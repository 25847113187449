import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import { meta } from "vue-meta"

import "bootstrap";
import "./scss/custom.scss";

const app = createApp(App);

app.use(store);
app.use(router);

// const metaManager = createMetaManager();

// app.use(metaManager);

app.mount("#app");
