
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "CompetitionComponent",
  props: {
    name: String,
    url: String,
    description: String,
    dates: String,
  },
  setup(props) {
    const hasUrl = computed<boolean>(() => {
      if (props == undefined || props.url == undefined) return false;
      return props.url.length > 0;
    });

    const hasDates = computed<boolean>(() => {
      if (props == undefined || props.dates == undefined) return false;
      return props.dates.length > 0;
    });

    return {
      hasUrl,
      hasDates,
    };
  },
});
