import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54e56ec6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col" }
const _hoisted_2 = { class: "card text-center" }
const _hoisted_3 = { class: "card-header flextext" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "card-text" }
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.name), 1),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.hasDates)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.dates), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.description), 1),
        (_ctx.hasUrl)
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: _ctx.url,
              target: "_blank",
              class: "card-link"
            }, "Arrangörens webbsajt", 8, _hoisted_7))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}